<template>
    <div id="job-center-header">
      <h1 class="job-center">Rozwijaj swoją karierę razem z nami</h1>
    </div>
    <section class="wrap jobs" v-if="dataReady">
                <div v-for="job in items" :key="job.id" >
                    <div>
                        <div> {{ job.stanowisko }}</div>
                        <div> {{ job.kraj }}, {{ job.miasto }}</div>
                        <div> {{ job.data_waznosci }}</div>
                        <div> {{ job.tryb_pracy }} </div>
                        <div> {{ job.pracodawca.nazwa }}</div>
                    </div>
                </div>
            </section>

<Footer/>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue';
export default {
        name: 'JobOffers',
        props: ['job-id'],
        components: {
            Footer
        },

data() {
    return {
      items: [],
      dataReady: false
    };
  },

async mounted() {
        let headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json"
        }

        let bodyContent = JSON.stringify({
          "klucz": "a05b8e3ce3a76f07bb162c6b0ab87940"
        });

        await fetch("https://administration.secgrupa.com/ogloszenia/lista.php", { 
          method: "POST",
          body: bodyContent,
          headers: headersList 
        })
        .then(res =>res.json())
        .then(data => this.items = data.ogloszenia)
      
        this.dataReady = true;

        console.log(this.items);

    }
  }

</script>

<style>
@import './JobOffers.css'
</style>