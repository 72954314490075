<template>
    <section id="welding-machines-section">
        <div class="wrap welding-machines">
            <div class="welding-machines-content">
                <div class="welding-machines-info">
                    <p>Our 80-square-meter welding workshop features four fully equipped stations, 
                    designed for site tests and professional training. 
                    With modern welding equipment and ample space, we provide an optimal environment 
                    for both skill assessments and hands-on training sessions.</p>
                <ul>
                    <li>TIG machines: Lincoln electric invertec 300tpx + Coolarc 21</li>
                    <li>MIG/MAG machines: ESAB Warrior 400i CC/CV + Feed 304</li>
                    <li>Tecma fume extractor arms TYPE RS for each stand</li>
                    <li>Welding table with tooling for each stand</li>
                    <li>Assembling table with tooling</li>
                    <li>+ Outdoor stand for grooving and cutting</li>
                </ul>
                </div>
                <div class="welding-machines-img">
                    <img class="side-pic" :src="images. WeldingMachinesIMG" crossorigin=""/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WeldingMachines',

    data(){
            return{
                images: {
                    WeldingMachinesIMG: require('@/assets/Training/Welding-Training-02.jpg')
                },
            }
    
        },

    created () {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        counters(){
        const figures = document.querySelectorAll('.figure');
        

        figures.forEach(figure=> {
            const updateCount = () => {
                const target = figure.getAttribute('data-target');

                console.log(target);
            }
            updateCount();
        })

        }

    }
}
</script>

<style src='./WeldingMachines.css'>

</style>
