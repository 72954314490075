<template>
    <section id="theoretical-section">
        <div class="wrap theoretical">
            <div class="theoretical-content">
                <div class="theoretical-info">
                    
                    <p>We approach the testing and training process holistically, 
                      ensuring a solid foundation with a theoretical course that complements hands-on practice.</p>
                    <p>Our theory sessions are led by certified specialists with extensive experience. It results in comprehensive practical and theoretical training.</p>
                </div>
                <div class="theoretical-img">
                    <img class="side-pic" :src="images.TheoreticalIMG" crossorigin=""/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Theoretical',

    data(){
            return{
                images: {
                    TheoreticalIMG: require('@/assets/Training/Theoretical-01.jpg')
                },
            }
    
        },

    created () {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        counters(){
        const figures = document.querySelectorAll('.figure');
        

        figures.forEach(figure=> {
            const updateCount = () => {
                const target = figure.getAttribute('data-target');

                console.log(target);
            }
            updateCount();
        })

        }

    }
}
</script>

<style src='./Theoretical.css'>

</style>
