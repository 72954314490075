<template>
    <section id="welding-part-section">
        <div class="wrap welding-part">
            <h2>Welding area</h2>
            <div class="welding-part-content">
                <div class="welding-part-info">
                    <p>We provide comprehensive site testing for our clients based on their technical documentation. To ensure that all assigned personnel are prepared and skilled, we offer thorough training and testing for TIG and MIG/MAG welders, as well as for pipe and structural fitters.</p>
                    <p>All training and assessments are conducted under the supervision of experienced on-site production staff, certified welding engineers, and inspectors, ensuring high standards of quality and readiness.</p>
                </div>
                <div class="welding-part-img">
                    <img class="side-pic" :src="images.WeldingPartIMG" crossorigin=""/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'WeldingPart',

    data(){
            return{
                images: {
                    WeldingPartIMG: require('@/assets/Training/Welding-Training-01.jpg')
                },
            }
    
        },

    created () {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        counters(){
        const figures = document.querySelectorAll('.figure');
        

        figures.forEach(figure=> {
            const updateCount = () => {
                const target = figure.getAttribute('data-target');

                console.log(target);
            }
            updateCount();
        })

        }

    }
}
</script>

<style src='./WeldingPart.css'>

</style>
