import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../views/LandingPage/LandingPage.vue'
import NewsCenter from '../views/NewsCenter/NewsCenter.vue'
import Story from '../views/NewsCenter/Story.vue'
import Insulation from '../views/Services/Insulation.vue'
import Scaffolding from '../views/Services/Scaffolding.vue'
import Welding from '../views/Services/Welding.vue'
import Contact from '../views/Contact/Contact.vue'
import References from '../views/References/References.vue'
import ResponsibleSEC from '../views/ResponsibleSEC/ResponsibleSEC.vue'
import Article from '../views/ResponsibleSEC/Article.vue'
import Gdpr from '../views/GDPR/gdpr.vue'
import PrivacyStatment from '../views/GDPR/PrivacyStatment.vue'
import JobOffers from '../views/JobOffers/JobOffers.vue'
import TrainingCenter from '../views/TrainingCenter/TrainingCenter.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage,
  },

  { path: '/:catchAll(.*)',
    redirect: { name: 'Home' },
  },

  {
    path: '/responsible-sec',
    name: 'Responsible',
    component: ResponsibleSEC,
  },

  {
    path: '/responsible-sec/:slug',
    name: 'Article',
    component: Article,
    props: true,
  },

  {
    path: '/news',
    name: 'News',
    component: NewsCenter,
  },

  {
    path: '/story/:slug',
    name: 'Story',
    component: Story,
    props: true,
  },

  {
    path: '/services/insulation',
    name: 'Insulation',
    component: Insulation,
  },

  {
    path: '/services/scaffolding',
    name: 'Scaffolding',
    component: Scaffolding,
  },

  {
    path: '/services/welding',
    name: 'Welding',
    component: Welding,
  },

  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },

  {
    path: '/references',
    name: 'References',
    component: References,
  },

  {
    path: '/gdpr/:slug',
    name: 'Gdpr',
    component: Gdpr,
    props: true,
  },

  {
    path: '/privacy-statment',
    name: 'PrivacyStatment',
    component: PrivacyStatment,
  },

  {
    path: '/kariera',
    name: 'kariera',
    component: JobOffers,
  },

  {
    path: '/training-center',
    name: 'TrainingCenter',
    component: TrainingCenter,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
})

export default router
