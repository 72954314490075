<template>
    <header id="navigation-section" class="first-level-break-app-layout">
        <nav class="navigation" id="mainNav">
            <router-link to="/"><img :src="images.logo" alt="Logo SEC" crossorigin=""/></router-link>
            <ul class="first-level-nav-text">
                <li class="dropdown">
                    <button class="first-level-nav-text">Services</button>
                    <ul class="dropdown-content">
                        <li class="scn-level-nav-text"><router-link :to="{ name: 'Insulation' }">Insulation</router-link></li>
                        <li class="scn-level-nav-text"><router-link :to="{ name: 'Scaffolding' }">Scaffolding</router-link></li>
                        <li class="scn-level-nav-text"><router-link :to="{ name: 'Welding' }">Welding</router-link></li>
                    </ul>
                </li>
                <li class="dropdown">
                    <button class="first-level-nav-text">About Us</button>
                    <ul class="dropdown-content">
                        <li class="scn-level-nav-text newsy"><router-link :to="{ name: 'News' }">News center</router-link></li>
                        <li class="scn-level-nav-text newsy"><router-link :to="{ name: 'References' }">References</router-link></li>
                        <li class="scn-level-nav-text newsy"><router-link :to="{ name: 'Responsible' }">Responsible SEC</router-link></li>
                        <li class="scn-level-nav-text newsy"><router-link :to="{ name: 'PrivacyStatment' }">Privacy statment</router-link></li>     
                    </ul>
                </li>
                <li class="first-level-nav-text"><router-link :to="{ name: 'TrainingCenter' }">Training Center</router-link></li>
                <li class="first-level-nav-text"><router-link :to="{ name: 'Contact' }">Contact</router-link></li>
            </ul>
            <label for="check">
                <input type="checkbox" id="check" @click="responsiveNav" /> 
                <span></span>
                <span></span>
                <span></span>
            </label>
            <a class="call-us-menu" href="tel:+48585507066">Call us</a>
        </nav>
    </header>
</template>


<script>


export default {
    name: 'Menu',
     data() {
                    return {
                        images: {
                            logo: require('../../assets/Branding/SEC logo color.svg')
                        }
                    }

            },
    
    methods: {
        responsiveNav(){
                var nav = document.getElementById("mainNav");
                var navSection = document.getElementById("navigation-section");
                if (nav.className === "navigation" && navSection.className === "first-level-break-app-layout") {
                    nav.className += " responsive",
                    navSection.className += " responsive";
                } else {
                    nav.className = "navigation",
                    navSection.className = "first-level-break-app-layout";
        }}   
        
        }}
</script>



<style>
@import './Menu.css';
</style>