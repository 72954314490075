<template>
    <section id="location-section">
        <div class="wrap location">
            <h2>Beneficial location in Gdańsk, the biggest city of North Poland</h2>
            <div class="location-content">
                <div class="location-info">
                    <p>Greatly communicated location supports easy access to the testing site both for client supervisors and the trainees.</p>
                        <ul>
                            <li>near to Central Rail Station and Main Airport in the region</li>
                            <li>only 1,5h flight from mayor Norwegian cities</li>
                            <li>many bus and tram stops in close neighborhood</li>
                            <li>affordable hotels for workers on the same street</li>
                            <li>close proximity to highways and expressways</li>
                        </ul>
                </div>
                <div class="location-img">
                    <img class="side-pic" :src="images.MapIMG" crossorigin=""/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'LocationPart',

    data(){
            return{
                images: {
                    MapIMG: require('@/assets/bg/Mapa dojazdu.png')
                },
            }
    
        },

    created () {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        counters(){
        const figures = document.querySelectorAll('.figure');
        

        figures.forEach(figure=> {
            const updateCount = () => {
                const target = figure.getAttribute('data-target');

                console.log(target);
            }
            updateCount();
        })

        }

    }
}
</script>

<style src='./LocationPart.css'>

</style>
