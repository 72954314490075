<template>
    <section id="insulation-part-section">
        <div class="wrap insulation-part">
            <h2>Insulation area</h2>
            <div class="insulation-part-content">
                <div class="insulation-part-info">
                    <p>The 160-square-meter insulation training center features five fully equipped workstations for teaching and testing practical 
                        skills in various insulation installation techniques. </p>
                        
                    <p>Additionally, we have designated a fully ventilated, isolated area for 
                        prefabrication of dust-generating materials, along with a dedicated storage space for insulation materials, like:</p>
                    <ul>
                        <li>Pyrogel</li>
                        <li>K-flex</li>
                        <li>Armaflex</li> 
                        <li>Foamglas</li>
                    </ul>
                </div>
                <div class="insulation-part-img">
                    <img class="side-pic" :src="images.InsulationPartIMG" crossorigin=""/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'InsulationPart',

    data(){
            return{
                images: {
                    InsulationPartIMG: require('@/assets/Training/Insulation-Training-02.jpg')
                },
            }
    
        },

    created () {
        window.addEventListener('scroll', this.onScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        counters(){
        const figures = document.querySelectorAll('.figure');
        

        figures.forEach(figure=> {
            const updateCount = () => {
                const target = figure.getAttribute('data-target');

                console.log(target);
            }
            updateCount();
        })

        }

    }
}
</script>

<style src='./InsulationPart.css'>

</style>
