<template>
    <section id="contact-section">
        <div class="wrap contacts">
                <div class="contact-block">
                    <form class="contact-form" v-on:submit.prevent='sendContactForm(contactName, contactEmail, contactTel, contactMsg, privacyConsent)'>
                        <h2> Working together we have power to deliver your projects faster, let's talk</h2>

                        <label for="name-form">Name</label>
                        <input type="text" v-model="contactName" id="name-form" placeholder="Please enter your name">

                        <label for="email-form">Email</label>
                        <input type="email" v-model="contactEmail" id="email-form" placeholder="you@your.com">

                        <label for="tel-form">Phone</label>
                        <input type="tel" v-model="contactTel" id="tel-form" placeholder="i.e. +48603085610" >

                        <label for="msg-form">Message</label>
                        <textarea id="msg-form" v-model="contactMsg" rows="5" placeholder="Please tell us what kind of specialist you need for your project"></textarea>
                        <label>
                            <input type="checkbox" v-model="privacyConsent" />
                            by submiting this form I agree to the terms and conditions of <router-link :to="'/gdpr/polityka-prywatnosci'" tag="a" target="_blank"><h3 class="all-privacy-title">Privacy policy</h3></router-link>
                        </label>
                        
                        <input type="submit" value="Send">
                    </form>

                    <section class="headquarters">
                        <div class="admin">
                            <h2 class="market">Headquarters and Admin</h2>
                            <h3>SEC Sp. z o. o.</h3>
                            <ul>
                                <li>Twarda 12</li>
                                <li>80-871 Gdańsk</li>
                                <li>Poland</li>
                                <li>VAT No.: PL583 324 35 74</li>
                            </ul>
                            <ul>
                                <li>T: +48 58 550 70 66</li>
                                <li>E: info@secgrupa.com</li>
                            </ul>
                            <ul>
                                <li>Opening hours:</li>
                                <li>Mon - Fri</li>
                                <li>07:00 - 15:00</li>
                            </ul>
                            <div class="contact">
                                <h3>Sales Department</h3>
                                <ul>
                                    <li>NO: +47 488 86 567</li>
                                    <li>T: +48 585 507 066 ext. 6</li>
                                    <li>E: m.pilat@secgrupa.com</li>
                                </ul>
                            </div>
                            <div class="contact">
                                <h3>GDPR - Data Protection Officer</h3>
                                <ul>
                                    <li>E: iod@secgrupa.com</li>
                                </ul>
                            </div>
                        </div>
                        <div class="contact-info">
                            <div class="contact">
                                <h3>Recruitment Department</h3>
                                <ul>
                                    <li>T: +48 585 507 066 ext. 1</li>
                                    <li>E: praca@secgrupa.com</li>
                                </ul>
                            </div>
                            <div class="contact">
                                <h3>Training Center</h3>
                                <ul>
                                    <li>T: +48 585 507 066 ext. 2</li>
                                    <li>E: szkolenia@secgrupa.com</li>
                                </ul>
                            </div>
                            <div class="contact">
                                <h3>HR Department</h3>
                                <ul>
                                    <li>T: +48 585 507 066 ext. 3</li>
                                    <li>E: kadry@secgrupa.com</li>
                                </ul>
                            </div>
                            <div class="contact">
                                <h3>Transport Department</h3>
                                    <ul>
                                        <li>T: +48 585 507 066 ext. 4</li>
                                        <li>E: transport@secgrupa.com</li>
                                    </ul>
                            </div>
                            <div class="contact">
                                <h3>Financial Department</h3>
                                    <ul>
                                        <li>T: +48 585 507 066 ext. 5</li>
                                        <li>E: financial@secgrupa.com</li>
                                    </ul>
                            </div>
                        </div>
                    </section>
                </div>


                <section class="companies">
                    <div class="company">
                        <h2 class="market">EU market</h2>
                        <h3>SEC Pomorze Sp. z o. o.</h3>
                        <ul>
                            <li>Twarda 12</li>
                            <li>80-871 Gdańsk</li>
                            <li>Poland</li>
                            <li>VAT No.: PL957 104 71 98</li>
                        </ul>
                    </div>
                    <div class="company">
                        <h2 class="market">Norwegian market</h2>
                        <h3>SEC Norge AS</h3>
                        <ul>
                            <li>Amlievegen 13</li>
                            <li>5416 Stord</li>
                            <li>Norway</li>
                            <li>VAT No.: NO898 125 882</li>
                        </ul>
                    </div>
                    <div class="company">
                        <h2 class="market">German market</h2>
                        <h3>SEC GmbH</h3>
                        <ul>
                            <li>Kurfürstendamm 226</li>
                            <li>10719 Berlin</li>
                            <li>Germany</li>
                            <li>HRB 248467 B</li>
                        </ul>
                    </div>
                    <div class="company">
                        <h2 class="market">UK market</h2>
                        <h3>SEC GB&amp;NI Ltd.</h3>
                        <ul>
                            <li>18 London Road</li>
                            <li>Portsmouth PO2 0LH</li>
                            <li>United Kingdom</li>
                            <li>Reg No.: 13763919</li>
                        </ul>
                    </div>
                    <div class="company">
                        <h2 class="market">Training center</h2>
                        <h3>SEC Operations Sp. z o. o.</h3>
                        <ul>
                            <li>Twarda 12</li>
                            <li>80-871 Gdańsk</li>
                            <li>Poland</li>
                            <li>VAT No.: PL583 344 49 76</li>
                        </ul>
                    </div>
                </section>
                
        </div>
    </section>
    <TrustedUs/>
    <CallUs/>
    <Footer/>
</template>

<script>
    import TrustedUs from '@/components/TrustedUs/TrustedUs.vue'
    import CallUs from '@/components/CallUs/CallUs.vue'
    import Footer from '@/components/Footer/Footer.vue'
    
    export default {
        name: 'Contact',
        components: { 
            TrustedUs,
            CallUs,
            Footer
            },

            data () {
                return {
                    contactForm: [],
                    contactName: '',
                    contactEmail: '',
                    contactTel: '',
                    contactMsg: ''
                }
            },

            methods: {

                sendContactForm: function(NameForm, EmailForm, TelForm, MsgForm, PrivacyForm) {
 
                    const regName = /^[ \u00c0-\u01ffa-zA-Z'-]+$/;
                    const regEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                    const regTel = /\(?\+[0-9]{1,3}\)? ?-?[0-9]{1,3} ?-?[0-9]{3,5} ?-?[0-9]{4}( ?-?[0-9]{3})? ?(\w{1,10}\s?\d{1,6})?/;
                    const regMsg = /^[0-9\s\u00c0-\u01ffa-zA-Z'.,!-]+$/;

                    let checkNameAndMsg = false;
                    let checkTelOrEmail = false;
                    let checkForm = false;
                    let checkPrivacy = false;


                    if (regName.test(NameForm)) {
                        console.log("Name is " + regName.test(NameForm))   
                        if (MsgForm === '' || regMsg.test(MsgForm)) { 
                            checkNameAndMsg = true; }
                    }

                    if (regTel.test(TelForm) || regEmail.test(EmailForm)) {
                        checkTelOrEmail = true;
                    }

                    if (checkNameAndMsg && checkTelOrEmail) {
                        checkForm = true;
                    }

                    if (PrivacyForm) {
                        checkPrivacy = true;
                    }
     
                    if (checkForm && checkPrivacy) {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ data: {Name: NameForm, Email: EmailForm, Phone: TelForm, Message: MsgForm} })
                    };
                    fetch(this.$mainlink + this.$contactformlink, requestOptions)
                        .then(async response => {
                        const data = await response.json();

                        // check for error response
                        if (!response.ok) {
                            // get error message from body or default to response status
                            const error = (data && data.message) || response.status;
                            return Promise.reject(error);
                        }

                        this.postId = data.id;
                        })
                        .catch(error => {
                        this.errorMessage = error;
                        console.error('There was an error!', error);
                        });

                        this.contactName = ''
                        this.contactEmail = ''
                        this.contactTel = ''
                        this.contactMsg = ''
                } else {
                    console.log("not valid input")
                } 
                }
                

            }
        }
</script>

<style src="./Contact.css"></style>